<template>
  <div class="amendments-container">
      <h1 style="margin-left: 20px;">{{$t("help-support")}}</h1>
      <div class="support-con">
          <div class="con1">
              <a href="https://support.bos-id.eu/support/solutions/articles/36000453908-erste-schritte-bos-id" class="text-black" target="blank">
                <h3 class="active">{{$t("first-step")}}</h3>
              </a>
              <a href="https://support.bos-id.eu/support/solutions/folders/36000273151" class="text-black" target="blank">
                <h3>FAQ</h3>
              </a>
              <a href="https://support.bos-id.eu/support/home" class="text-black" target="blank">
                <h3>Support</h3>
              </a>
          </div>
          <div class="con2">
              <!-- <p>Link to: </p>
              <span></span>
              <span>https://www.bosid.eu/portal/erste_schritte</span>
              <span>https://www.bosid.eu/portal/erste_schritte</span> -->
          </div>
      </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Popup from '../../components/dashboard/PopupExpiryDate.vue'
export default Vue.extend({
  name: 'ExpiredIDCards',
  components:{
      Popup
  },
  methods:{
      popUp(user){
          this.prop_user=user;
          const popup=this.$refs.popup ;
          popup.open();
      }
  },
  data(){
      return{
          expiry_date :"",
          search:'',
          prop_user:[],
          users:[
            {
              application_from:"21.07.2021 ",
              organisation:"Feuerwehr Musterstadt (A-Dorf)",
              name:"Jacobsen Daniela"
            },
            {
              application_from:"29.07.2021",
              organisation:"Feuerwehr Musterstadt (A-Dorf)",
              name:"Mächtel Wolfgang"
            },
            {
              application_from:"03.08.2021 ",
              organisation:"Feuerwehr Musterstadt (A-Dorf)",
              name:"Mustermann Manfred"
            },
            {
              application_from:"11.08.2021",
              organisation:"Feuerwehr Musterstadt (B-Dorf)",
              name:"Olbert Rüdiger"
            },
            {
              application_from:"23.08.2021",
              organisation:"Feuerwehr Musterstadt (B-Dorf)",
              name:"Olbert Rüdiger"
            },
          ]
      }
  }
});
</script>
<style>

</style>